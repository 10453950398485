import { Title } from '@solidjs/meta';
import { Label, Radio, RadioBar, RadioBarButton, RadioGroup } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function RadioPage() {
	return (
		<>
			<Title>Radio | Forms | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Radio</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="RadioGroup">
					<RadioGroup name="radios">
						<Label>A radio group</Label>
						<Radio value="yes">
							<Label>Yes</Label>
						</Radio>
						<Radio value="no">
							<Label>No</Label>
						</Radio>
					</RadioGroup>
				</StyleCard>

				<StyleCard title="RadioBar">
					<RadioGroup name="radiobars">
						<Label>A radio bar</Label>
						<RadioBar>
							<RadioBarButton value="yes">
								<Label>Yes</Label>
							</RadioBarButton>
							<RadioBarButton value="no">
								<Label>No</Label>
							</RadioBarButton>
						</RadioBar>
					</RadioGroup>
				</StyleCard>
			</div>
		</>
	);
}
